body, html {
  margin: 0;
  color: #fff;
  font-family: 'Noto Sans', sans-serif;
  letter-spacing: 0.64px;
   background-color: #064457;
}


@media screen and (min-width: 423px) {
  body {
    padding-bottom: 2em;
  }
}

@import url('https://fonts.googleapis.com/css?family=Noto+Sans:400,700&display=swap');


button:focus {
  outline: none;
}


@media screen and (min-width: 1441px) {
  body {
    max-width: 1600px;
    margin: 0 auto;
    width: 100%; 
  }

  html {
    background-color: #05556D;
  }
}

/* comment out to  fix the bug */
/*
@media screen and (min-width: 1441px) and (min-height: 1150px) {
  body {
    height: fill-available;
  }
}


html {
  min-height: fill-available;
}
*/


body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  
}

/**********
 app
************/
#app {
  width: 100%;
}


@media screen and (min-width: 1251px) {
  #app {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 10px;
  }
}

@media screen and (max-width: 1250px) {
  #app {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
}

#app.condition_1 {
  min-width: 740px;
  padding: 2.5em 0;
}

#app.condition_2 {
  min-width: 642px;
  padding: 2em 0;
}

#app.condition_3 {
  min-width: 500px;
  padding: 1.5em 0;
}

#app.condition_4 {
  min-width: 348px;
  padding: 1em 0;
}

#app.condition_5 {
  min-width: 250px;
  padding: 1em 0;
}


/************************
  game-viewer / Board
*************************/

#game-viewer {
 /*  margin: 0 auto;*/
  box-shadow: 10px 10px 3px rgba(0, 0, 0, 0.5);
}

#border {
  width: 100%;
  height: 100%;
  /* rgb(51, 49, 49); */
}

#game-viewer.condition_1 {
  width: calc(3.125em * 15 - 0.0625em * 15);
  height: calc(3.125em * 15 - 0.0625em * 15);
}

#game-viewer.condition_2 {
  width: calc(3.125em * 13 - 0.0625em * 13);
  height: calc(3.125em * 13 - 0.0625em * 13);
}

#game-viewer.condition_3 {
  width: calc(3.125em * 10 - 0.0625em * 10);
  height: calc(3.125em * 10 - 0.0625em * 10);
}

#game-viewer.condition_4 {
  width: calc(3.125em * 7 - 0.0625em * 7);
  height: calc(3.125em * 7 - 0.0625em * 7);
}

#game-viewer.condition_5 {
  width: calc(3.125em * 5 - 0.0625em * 5);
  height: calc(3.125em * 5 - 0.0625em * 5);
}

.square {
  background-color: rgb(221, 154, 9); /*  rgb(201, 140, 9) */
  border: .0625em solid #000;
  height: 3.125em; /* 50px */
  width: 3.125em;
  font-size: 1em;
  margin-top: -.0625em;
  margin-left: -.0625em; 
  float: left;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.chess {
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.clear-fix {
  clear: both;
}

.playerOneColor {
  background-color: #fff; /* rgb(119, 0, 0); */
  box-shadow: 1px 2px 1px rgb(43, 40, 40);
}

.playerTwoColor {
  background-color: #000; /* rgb(59, 103, 160); */
  box-shadow: 1px 2px 1px rgb(43, 40, 40);
}


.winnerColor {
  animation: blink-animation 1s steps(2, start) 5 reverse;
  background-color: gold ;
}

@keyframes blink-animation {
  from {
    background-color: gold ;
  }

  to {
    background-color: rgb(221, 154, 9); /* gold; */
  }
}



/*************************
  Viewer 
*************************/
#switch-languages {
  width: fit-content;
}

@media screen and (min-width: 1251px) {
  #round-viewer {
    width: 10em;
    height: 22em;
    padding: 1em;
  }

  #round-viewer .chess {
    margin: 20% auto;
  }

  #replayBtn {
    margin-top: 1.5em;
  }

  #round-viewer div:nth-child(3) {
    width: fit-content;
    margin: 15% auto;
 
  }

  #switch-languages {
    margin: 0 auto 2em auto;
  }

  #controller {
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 1250px) and (min-width: 938px) {
  #controller {
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 1em;
  }

  #switch-languages {
    margin-left: 1em;
  }
}

@media screen and (max-width: 984px) {
    #controller {
      width: 95%;
      margin-bottom: 1em;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    #switch-languages {
      align-self: flex-start;
      margin-bottom: 1em;
      margin-left: 1em;
      display: flex;
      flex-direction: row;
      
    }
}

@media screen and (max-width: 1250px) and (min-width: 423px) {
  #round-viewer {
    width: 500px;
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center; 
    padding: 1em 0.5em;
    box-sizing: content-box;
  }

  #round-viewer h3 {
    display: inline;
  }

  #round-viewer .chess {
    margin: 1.5em auto 1em auto;
  }

  #round-viewer div:not(.chess) {
    width: 30%;
  }

  #replayBtn {
    margin-left: 15%;
  }
}

@media screen and (max-width: 984px) and (min-width: 758px) {
  #round-viewer {
    width: 80%;
    
  }
}

@media screen and (max-width: 767px) and (min-width: 423px) {
  #round-viewer {
    width: 90%;
    height: 100px;
    box-sizing: content-box;
    padding: 10px;
  }

  #round-viewer * {
    font-size: 1em;
  }

  #replayBtn {
    margin-left: 5px;
  }
}

@media screen and (max-width: 424px) {
  #round-viewer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 130px;
    padding: 0;
    width: 90%;
  }

  #round-viewer div:not(.chess) {
    display: flex;
    flex-direction: row; 
  }

  #round-viewer div:nth-child(1):not(.chess) {
    width: 65%;
    height: 60px;
   /* justify-self: flex-end; */  
  }

  #round-viewer div:nth-child(2):not(.chess) {
    width: 65%;
    height: 60px;
   
  }

  #round-viewer .chess {
    width: 30px;
    height: 30px;
    margin: 1em 10px;
  }

  #round-viewer h3 {
    font-size: 1em;
    margin: 10px;
  }
  
  #round-viewer div:nth-child(3) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35%;
    height: 120px;
  }
}

#round-viewer {
  border: 2px solid #70C4DE;
  border-radius: 10px;
  background-color: #0B80A3;
}

.controller-button {
  padding: 1em 1em; 
  border-radius: 8px;
  border: none;
  background-color: #0B3A48;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.5);
  font-size: 1em;
  width: fit-content;
  /* */
  text-align: center;
  height: 50px;
}

 .controller-button:active {
 /* background-color: rgb(151, 221, 253); */
 background-color: rgba(11, 58, 72, 0.8);
 box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
 transform: translate(0, 3px);
}


@media screen and (max-width: 423px) {
  .controller-button {
    font-size: 0.8em;
    padding: 1em 1em;
  }
}


/********************
    Record viewer 
*********************/
#record-viewer {
  background-color: #70C4DE;
  border-radius: 10px;
  box-sizing: border-box;
}

@media screen and (min-width: 1251px) {
  #record-viewer {
    padding: 0 0.2em;
  }
}

@media screen and (max-width: 1250px) {
  #record-viewer {
    padding: 0.2em;
  }
}

/* #game-record-conculsion */
#game-record-conculsion {
  background-color: inherit;
  border-radius: 10px 10px 0 0;
  display: flex;
  flex-direction: column;
}

#game-record-conculsion>div {
  display: flex;
  flex-direction: row;
  margin: 15px auto 10px auto;
  justify-content: center;
  align-items: center;
}

#game-record-conculsion div:nth-child(2n) {
  width: 100%;
  height: fit-content;
  font-size: 1.8em;
  margin: 0;
  box-sizing: content-box;
}

#game-record-conculsion div:nth-child(2) div {
  padding: 0 5px;
  font-weight: bold;
  color: black;
}

#game-record-conculsion>div p {
  width: 60px;
  font-weight: bold;
  text-align: center;
  margin: 0;
}


/* #game-record */
#game-record {
  background-color: #165A7C;
  /* border: 3px solid #70C4DE; */
  box-sizing: border-box;
}

#roundResult li {
  height: fit-content;
  margin-top: 0.4em;
  margin-bottom: 0;
  padding-bottom: 0.3em;
  font-size: 1.5em;
  font-weight: bold;
  list-style: none;
  float: right;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#roundResult li.Recorded {
  border-bottom: 2px dashed white;
}

#roundResult {
  margin-block-start: 0;
  list-style-position: outside;
  margin: 0;
  padding-inline-start: 20px;
  padding-inline-end: 20px;
}

#game-record .chess {
  margin: 0 37px;
  box-sizing: content-box;
  display: inline;
}

li.notRecord {
  border-bottom: 2px dashed rgba(255, 255, 255, 0.05);
}

div.listNum {
  width: 30px;
  height: 30px;
}

div.notRecord {
  color: rgba(255, 255, 255, 0.2);
  font-weight: normal;
}

.chess.notRecord {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

/* #resetBtn */
#resetBtn {
  text-align: center;
}

/* media queries */
@media screen and (min-width: 1251px) {
  #record-viewer {
    width: 180px;
    height: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #game-record-conculsion {
    width: 100%;
    height: 110px;
  }

  #game-record {
    width: 100%;
    height: 500px;
  }

  #resetBtn {
    margin-top: 1.2em;
  }
}

@media screen and (max-width: 1250px) and (min-width: 768px) {
  #record-viewer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 90%;
    height: 170px;
    margin-top: 2em;
  }

  #record-viewer>div:nth-child(3) {
    order: 2;
    width: 23%;
    display: flex;
    justify-content: center;
  }

  #game-record-conculsion {
    width: 23%;
    order: 1;
    height: fit-content;
    align-self: center;
   
  }

  #game-record-conculsion>div {
    margin: 5px;
  }

  #game-record-conculsion>div:nth-child(2n) {
    font-size: 1.5em;
  }


  #game-record {
    height: 100%;
    width: 75%;
    order: 3;
    margin-left: 2%;
    border-radius: 0 10px 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
  }

  #roundResult li {
    margin-bottom: 1.5em;
    margin-left: 0.8em;
    font-size: 1.5em;
    float: left;
  }

  #roundResult li:nth-child(6n) {
    clear: left;
  }

  #roundResult li:nth-child(5n+1) {
    margin-left: 0;
  }

  #roundResult li:nth-child(n+6) {
    margin-bottom: 0;
  }

  #game-record .chess {
    margin: 0 25px;
  }
}


@media screen and (max-width: 1056px) and (min-width: 938px) {
  #game-record .chess {
    margin: 0 18px;
  }
}

@media screen and (max-width: 937px) and (min-width: 768px) {
  #game-record .chess {
    margin: 0 9px;
  }

  #game-record {
    margin-left: 0;
  }
}


@media screen and (max-width: 767px) {
  #record-viewer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin-top: 2em;
  }

  #record-viewer>div:nth-child(3) {
    order: 2;
    width: 30%;
    display: flex;
    justify-content: center;
  }

  #game-record-conculsion {
    order: 1;
    width: 30%;
    margin-right: 10%;
  }

  #game-record {
    order: 3;
    width: 100%;
    border-radius: 0 0 10px 10px;
    padding: 1em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
     margin-top: 0.2em;
  }

  #game-record-conculsion>div {
    width: fit-content;
  }

  #game-record .chess {
    margin: 0 5px;
  }

  #roundResult {
     padding: 0;
  }

  #roundResult li {
    float: left;
    margin: 10px;
    font-size: 1em;
  }

  #roundResult li:nth-child(6n) {
    clear: left;
  }
}

@media screen and (max-width: 544px) and (min-width: 424px) {
  #game-record {
    padding: 0.8em 0;
   
  }
  
  #game-record .chess {
    margin: 0 0 0 3px;
  }

  #roundResult li {
    font-size: 0.8em;
  }

  div.listNum {
    width: 20px;
    height: 30px;
  }
}

@media screen and (max-width: 423px) {
  #record-viewer {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-content: center;
  }

  #game-record {
    border-radius: 0;
  }

  #roundResult {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;  
    order: 2;
    width: 100%;
  }

  #roundResult li:nth-child(6n) {
    clear: none;
  }

  #game-record-conculsion {
    width: 100%;
    margin: 0;
    order: 1;
  }

  #record-viewer>div:nth-child(3) {
    order: 3;
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 10px 0;
  }
}


/****************
  switch languages
****************/

#switch-languages {
  height: 2.5em;
  padding: 0;
}

#switch-languages button {
  width: 7em;
  height: 100%;
  border: none;
  color: white;
  font-weight: bold;
  font-size: 0.9em;
  margin: 0;
  padding: 0;
}

.lang-on {
  background-color: #0E5E5B;
  box-shadow: inset 3px 4px 3px 0 rgba(0, 0, 0, 0.50); 
}

.lang-off {
  background-color: #188F8B;
  box-shadow: 3px 4px 3px 0 rgba(0, 0, 0, 0.50);
}

#lang-tch {
  border-radius: 10px 0 0 10px;
}

#lang-en {
  border-radius: 0 10px 10px 0;
}

/*********
  information
***********/

#information {
  background-color: #B55A00;
  margin: 0 auto;
  padding: 0;
}

#howTo {
  border-bottom: 5px solid #D6D5D5;
}

#howTo h3,
#aboutAuthor h1 {
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  font-weight: bold;
}

#howTo p span {
  display: block;
}

#howTo p span:nth-child(1) {
  margin-top: 1em;
}
@media screen and (min-width: 1250px) {
  #information {
    width: 60em;
    padding: 2em 0;
    color: white;
  }

  #howTo, #aboutAuthor {
    display: flex;
    flex-direction: row;
    width: 90%;
    margin: 0 auto;
  }

  #howTo {
    padding-bottom: 2em;
  }

  #aboutAuthor {
    margin-top: 2em;
  }

  

  #howTo h3.EN, 
  #aboutAuthor h1.EN {
    font-size: 1em;
    width: 20%;
  }

  #howTo h3.zhTW,
  #aboutAuthor h1.zhTW {
    font-size: 1.1em;
    width: 20%;
    padding-left: 0.8em;
    box-sizing: content-box;
  }

  #howTo p,
  #aboutAuthor p {
    width: 80%;
    height: fit-content;
    margin: 0;
    font-size: 1em;
  }
}

@media screen and (max-width: 1249px) and (min-width: 423px) {
  #information {
    width: 80%;
    padding: 0.5em;
    box-sizing: content-box;
  }

  #howTo, #aboutAuthor {
    width: 85%;
    margin: 0 auto;
    padding-bottom: 2em;
    padding-top: 1em;
  }

  #howTo {
    margin-bottom: 1em;
    
  }

  #howTo h3,
  #aboutAuthor h1 {
    width: fit-content;
    margin: 0 auto;
    font-size: 1.2em;
    margin-bottom: 1.8em;
  }

  #howTo p,
  #aboutAuthor p {
    width: 100%;
    margin: 0 auto;
    font-size: 1em;
  }
}


@media screen and (max-width: 422px) {
  #information {
    width: 100%;
    padding: 0 1em;
    box-sizing: border-box;
  }

  #howTo h3,
  #aboutAuthor h1 {
    width: fit-content;
    margin: 0 auto;
    font-size: 1.2em;
    margin-bottom: 1.8em;
  }

  #howTo p,
  #aboutAuthor p {
    width: 90%;
    margin: 0 auto;
    font-size: 1em;
  }

  #howTo, #aboutAuthor {
    padding: 1.5em 0;
  }
}


a {
  text-decoration: none;  
  color: blue;
  font-weight: 400;
  font-style: bold;
}

a:hover {
  text-decoration: underline;
}